import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

import auth from './modules/auth';
import polls from './modules/polls';
import vehicleModels from './modules/vehicleModels';
import vehicleFleetAssociation from './modules/vehicleFleetAssociation';
import vehicleFleetAssociationList from './modules/vehicleFleetAssociationList';
import paymentMethod from './modules/paymentMethod';
import userAuth from './modules/userAuth';
import shipmentPartners from '../views/api-partners/state/shipmentPartners';
import providers from '../views/providers/state/providers';
import surge from "../views/surge/state/surge";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    auth: state.auth, // Include the 'auth' module
    app: {
      isMiniVariant: state.app.isMiniVariant,
      isThemeDark: state.app.isThemeDark
    },
    userAuth: {
      userAuthDocumentCount: state.userAuth.userAuthDocumentCount
    }
  })
});

export default new Vuex.Store({
  state: {
    app: {
      isLoadingOverlay: false,
      isMiniVariant: false,
      isThemeDark: false,
      totalDocumentsForIndexPagesBreadCrumbs: 0
    }
  },
  mutations: {
    SET_APP(state, app) {
      state.app = { ...state.app, ...app };
    }
  },
  actions: {
    setApp({ commit }, app) {
      commit('SET_APP', app);
    }
  },
  getters: {
    app: (state) => state.app
  },
  modules: {
    auth,
    polls,
    vehicleModels,
    vehicleFleetAssociation,
    vehicleFleetAssociationList,
    userAuth,
    shipmentPartners,
    providers,
    paymentMethod,
    surge
  },
  plugins: [vuexLocal.plugin]
});
