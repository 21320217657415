export default [
  {
    path: '/vehicles',
    name: 'vehicles',
    meta: {
      requiresAuth: true,
      permission: 'vehicles/models_list',
      title: 'Vehículos',
      module: 'vehicles',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-car-multiple'
    },
    component: () =>
      import(
        /* webpackChunkName: "vehicles" */ '@/views/vehicles/VehicleLayout.vue'
      ),
    children: [
      {
        path: '',
        name: 'models-list',
        meta: {
          adminLayoutNav: true,
          permission: 'vehicles/models_list',
          requiresAuth: true,
          layout: 'admin',
          title: 'Lista de modelos',
          module: 'vehicles',
          icon: 'mdi-car-cog'
        },
        component: () =>
          import(
            /* webpackChunkName: "vehicles-models" */ '@/views/vehicles/VehicleModels.vue'
          )
      },
      {
        path: 'fleet-association',
        name: 'vehicle-fleet-association',
        meta: {
          adminLayoutNav: true,
          permission: 'vehicles/vehicle_fleet_association',
          requiresAuth: true,
          layout: 'admin',
          title: 'Asociación vehículo y flota',
          module: 'vehicles',
          icon: 'mdi-clipboard-edit'
        },
        component: () =>
          import(
            /* webpackChunkName: "vehicles-models" */ '@/views/vehicles/VehicleFleetAssociation.vue'
          )
      },
      {
        path: 'config',
        name: 'config',
        meta: {
          adminLayoutNav: true,
          permission: 'vehicles/config',
          requiresAuth: true,
          layout: 'admin',
          title: 'Configuración',
          module: 'vehicles',
          icon: 'mdi-cog'
        },
        component: () =>
          import(
            /* webpackChunkName: "vehicles-colors" */ '@/views/vehicles/VehicleConfig.vue'
          )
      }
    ]
  }
];
